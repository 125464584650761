<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-menu
                                    ref="menu1"
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field outlined
                                                      v-model="selectedYear"
                                                      :label="$t('generic.lang_from')"
                                                      persistent-hint
                                                      prepend-icon="event"
                                                      v-on="on"
                                                      locale="de"
                                                      :disabled="loading"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker @click:year="saveYear" ref="yearPicker" :max="today" v-model="date1" locale="de" no-title @input="menu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field outlined
                                                      v-model="selectedYear2"
                                                      :label="$t('generic.lang_to')"
                                                      persistent-hint
                                                      prepend-icon="event"
                                                      v-on="on"
                                                      locale="de"
                                                      :disabled="loading"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker @click:year="saveYear2" ref="yearPicker2" :max="today" v-model="date2" locale="de" no-title @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col cols="12" md="4">
                                <v-checkbox v-model="sendMail" :label="$t('settings.lang_sendLinkViaEmail')"></v-checkbox>
                            </v-col>

                            <v-col v-if="sendMail" cols="12" md="6" offset-md="2">
                                <v-alert text color="primary">{{ $t('settings.lang_sendLinkViaEmailWarning') }}</v-alert>

                                <v-text-field v-model="recipientEmail" :label="$t('settings.lang_recipientAddress')" outlined prepend-icon="email"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" :loading="loading" :disabled="loading || (sendMail && recipientEmail.length < 3)" @click="downloadYear">{{ $t('generic.lang_download') }}</v-btn>
                        <v-btn color="primary" :loading="loadingPDF" :disabled="loadingPDF" @click="downloadPDF">{{ $t('generic.lang_easDownload') }}</v-btn>
                    </v-card-actions>
                </v-card>

                <v-card v-if="Object.keys(yearsDownload).length > 0" style="margin-top: 20px;">
                    <div v-for="(yearDownload, year) in yearsDownload" :key="year">
                        <v-toolbar dark flat>
                            <v-toolbar-title>{{ year }}</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-btn v-if="yearDownload.success" color="primary" target="_blank" :href="yearDownload.downloadURL">{{ year }} {{ $t('generic.lang_download') }}</v-btn>
                            <v-alert text color="primary" v-else>Es wurden für {{ year }} keine Daten gefunden</v-alert>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>
        </v-row>


    </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';

export default {
    name: "TSEExport",

    data() {
        return {
            today: new Date().toISOString().substr(0, 4),

            date1: new Date().toISOString().substr(0, 4),
            selectedYear: new Date().toISOString().substr(0, 4),
            menu1: false,

            date2: new Date().toISOString().substr(0, 4),
            selectedYear2: new Date().toISOString().substr(0, 4),
            menu2: false,

            loading: false,
            loadingPDF: false,
            sendMail: false,
            recipientEmail: "",

            yearsDownload: {}
        }
    },

    watch: {
        menu1(val) {
            val && this.$nextTick(() => (this.$refs.yearPicker.activePicker = 'YEAR'))
        },
        menu2(val) {
            val && this.$nextTick(() => (this.$refs.yearPicker2.activePicker = 'YEAR'))
        }
    },

    methods: {
        saveYear(year) {
            this.$refs.yearPicker.activePicker = 'YEAR';
            this.menu1 = false;

            this.selectedYear = year;
        },
        saveYear2(year) {
            this.$refs.yearPicker2.activePicker = 'YEAR';
            this.menu2 = false;

            this.selectedYear2 = year;
        },
        downloadPDF(item) {

            this.loadingPDF = true;
            this.axios.post('get/fiscal/de_easMeldung/', {
                softwareversion: process.env.VUE_APP_VERSION,
            },{responseType: 'arraybuffer'}).then((res) => {
                    //GENERATE PDF CONTENT FOR iFRAME
                    // const blobContent = new Blob([res.data], { type: "application/pdf" });
                    FileSaver.saveAs(new Blob([res.data], {
                        type: "application/pdf"
                    }), `${process.env.VUE_APP_VERSION}_EAS_Meldung.pdf`);
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loadingPDF = false;
                })
        },
        downloadYear() {
            this.yearsDownload = {};
            this.loading = true;

            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICE.EXPORTDATA.GET, {
                yearFrom: this.selectedYear,
                yearUntil: this.selectedYear2,
                sendMail: this.sendMail ? 1 : 0,
                recipientEmail: this.recipientEmail
            }).then((res) => {
                if(res.data.success && !this.sendMail) {
                    this.yearsDownload = res.data.yearsDownload;
                }
                else if(res.data.success && this.sendMail) {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_emailHasBeenSent'),
                        color: "success"
                    });
                }
                else {
                    Events.$emit("showSnackbar", {
                        message: res.data.msg,
                        color: "error"
                    });
                }

                this.loading = false;
            }).catch(() => {
                this.loading = false;

                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            })
        }
    }
}
</script>